<template>
  <Bar :chartData="chartData" :chartOptions="options" />
</template>

<script>
import { registerables } from 'chart.js';
import { Chart } from 'chart.js';
import { Bar } from 'vue-chartjs'

Chart.register(...registerables);

export default {
  components: { Bar },
  props: {
    chartData: { type: Object, default: null },
    options: { type: Object, default: null }
  },
};
</script>
