<template>
  <div>
    <b-card class="mt-6" v-if="pending_members_data">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <b-card v-else class="mt-6" header-border-variant="white" no-body>
      <template #header>
        <b-form-group :label="$t('DASHBOARD.COUNT')" label-for="selection">
          <b-form-select id="selection" :options="member_selection_options" v-model="type" />
        </b-form-group>

        <div v-if="type !== 'skv_intag' && type !== 'period_memberships'">
          <div class="date_box">{{ start_date }}</div>
          <div class="date_box_mid">-</div>
          <div class="date_box">{{ end_date }}</div>
        </div>
        
      </template>
      <div class="custom-card-body-2-column">

        <div class="title">
          <div class="d-flex flex-column">
            <span class="sales-title-text">{{ totals.total }}</span>
          </div>
          <img src="assets/images/iconbox_bg.png" alt="bg" />
        </div>
        <div class="data">
          <div class="member-lists">
            <div class="data-member-icon mb-3">
              <i class="member-icon-1"></i><span>{{$t('PAGES.DASHBOARD.MEN')}}</span>
              <span class="sales-title-text"
                >: {{ totals.male }}</span
              >
            </div>
            <div class="data-member-icon mb-3">
              <i class="member-icon-2"></i><span>{{$t('PAGES.DASHBOARD.WOMEN')}}</span>
              <span class="sales-title-text"
                >: {{ totals.female }}</span
              >
            </div>
            <div class="data-member-icon mb-3">
              <i class="member-icon-3"></i><span>{{$t('PAGES.DASHBOARD.OTHERS')}}</span>
              <span class="sales-title-text"
                >: {{ totals.other }}</span
              >
            </div>
          </div>
        </div>

      </div>
    </b-card>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
  SET_USER_PREFS
} from '@/core/services/store/common.module';
import store from '@/core/services/store';


export default {
  name: 'DashboardTotalMembersCard',
  components: {

  },
  props: ['start_date','end_date'],
  data() {
    return {
      member_selection_options: [
        { value: 'members', text: this.$t('DASHBOARD.MEMBERS') },
        { value: 'period_memberships', text: this.$t('DASHBOARD.PERIOD_MEMBERSHIPS') },
        { value: 'date_memberships', text: this.$t('DASHBOARD.DATE_MEMBERSHIPS') },
        { value: 'mucf_6_25', text: this.$t('DASHBOARD.MUCF_6_25') },
        { value: 'mucf_total', text: this.$t('DASHBOARD.MUCF_TOTAL') },
        { value: 'skv_intag', text: this.$t('DASHBOARD.SKV_INTAG') }
      ],
      pending_members_data: true,
      type: 'members',
      totals: {}
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete','user_prefs']),
  },
  mounted() {

    if (this.user_prefs) {
      if (this.user_prefs.dashboard) {
        this.type = this.user_prefs.dashboard.total;
      }
      else {
        this.user_prefs.dashboard = {
          total: 'members'
        };
      }
    }
    else {
      this.user_prefs = {
        dashboard: {
          total: 'members'
        }
      };
    }
  },
  watch: {
    start_date() {
      this.load_data();
    },
    end_date() {
      this.load_data();
    },
    type() {
      this.pending_members_data = true;

      this.user_prefs.dashboard.total = this.type;

      store.dispatch(SET_USER_PREFS, this.user_prefs);

      this.load_data();
    },

    currentCompanyId() {
      this.load_data();
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue != -1) {
        this.load_data();
      }
    }
  },
  methods: {

    update_prefs() {
      store.dispatch(SET_USER_PREFS, this.user_prefs);
    },

    load_data() {
      if (this.type == 'members') {
        this.load_member_gender_stats(this.start_date, this.end_date);
      }
      else if (this.type === 'skv_intag') {
        this.load_skv_stats(this.currentPeriodId);
      }
      else if (this.type === 'period_memberships') {
        this.load_membership_gender_stats_period(this.currentPeriodId);
      }
      else if (this.type === 'date_memberships') {
        this.load_membership_gender_stats(this.start_date, this.end_date);
      }
      else if (this.type === 'mucf_6_25') {
        this.load_mucf_counts(this.currentPeriodId, false);
      }
      else if (this.type === 'mucf_total') {
        this.load_mucf_counts(this.currentPeriodId, true);
      }
      else {
        this.load_member_gender_stats(this.start_date, this.end_date);

        this.type = 'members';
      }
    },

  
    async load_skv_stats(period_id) {
      try {
        this.memberships_stats_data = [];

        const res2 = await axios.get(
          `/company/membership_gender_stats/skv/${period_id}?total=true`
        );
        if (res2.status === 200) {
          this.totals = res2.data.members;
          this.pending_members_data = false;
        }
        else {
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_skv_stats error', err);
        this.pending_members_data = false;
      }

      return {};
    },


    async load_membership_gender_stats_period(period_id) {
      try {
        this.memberships_stats_data = [];

        const res2 = await axios.get(
          `/company/membership_gender_stats/${this.currentCompanyId}/period/${period_id}?total=true`
        );
        if (res2.status === 200) {
          this.totals = res2.data.members;
          this.pending_members_data = false;
        }
        else {
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_membership_gender_stats_period error', err);
        this.pending_members_data = false;
      }

      return {};
    },


    async load_mucf_counts(period_id, totals) {
      try {
        this.memberships_stats_data = [];

        const res = await axios.get(
          `/company/mucf/${this.currentCompanyId}/${period_id}`
        );
        if (res.status === 200) {
          if (totals) {
            this.totals = res.data.stats_total;
          }
          else {
            this.totals = res.data.stats_6_25;
          }
          
          this.pending_members_data = false;
        }
        else {
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_mucf_total error', err);
        this.pending_members_data = false;
      }

      return {};
    },

    async load_membership_gender_stats(start_date, end_date) {
      try {
        this.memberships_stats_data = [];

        const res2 = await axios.get(
          `/company/membership_gender_stats/${this.currentCompanyId}/dates?start_date=${start_date}&end_date=${end_date}&total=true`
        );
        if (res2.status === 200) {
          this.totals = res2.data.members;
          this.pending_members_data = false;
        }
        else {
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_membership_gender_stats error', err);
        this.pending_members_data = false;
      }

      return {};
    },

    async load_member_gender_stats_period(period_id) {
      try {
        this.members_stats_data = [];

        const res2 = await axios.get(
          `/company/member_gender_stats/${this.currentCompanyId}/period/${period_id}?total=true`
        );
        if (res2.status === 200) {
          
          this.totals = res2.data.members;
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_member_gender_stats_period error', err);
      }

      return {};
    },


    async load_member_gender_stats(start_date, end_date) {
      try {
        this.members_stats_data = [];

        const res2 = await axios.get(
          `/company/member_gender_stats/${this.currentCompanyId}/dates?start_date=${start_date}&end_date=${end_date}&total=true`
        );
        if (res2.status === 200) {
          
          this.totals = res2.data.members;
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_member_gender_stats error', err);
      }

      return {};
    },

  }
};
</script>

<style lang="scss" scoped>

  .date_box {
    border: 1px solid #1BC5BD;
    border-radius: 8px;
    width: 100px;
    padding: 6px;
    float: left;
  }

  .date_box_mid {
    float: left;
    width: 14px;
    height: 32px;
    margin-left: 8px;
    margin-top: 7px;
  }
  
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  
</style>
